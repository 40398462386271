export default `
Капуста,0
Морковь,0
Редис,0
Репа,0
Редька,0
Томаты,0
Огурцы,0
Баклажаны,0
Яблоки,1
Груши,1
Смородина,1
Малина,1
Земляника,1
Слива,1
Вишня,1
Алыча,1
Пшеница,2
Овёс,2
Кукуруза,2
Рис,2
Ячмень,2
Рожь,2
Подсолнух,3
Соя,3
Рапса,3
Клевер,4
Тимофеевка,4
Люцерна,4
Люпин,4
Сирень,4
Розы,4
Астры,4
Гвоздики,4
Ирисы,4
Гладиолусы,4
Лилии,4
Тюльпаны,4
Нарциссы,4
`.trim();
