import {MDCRipple} from "@material/ripple/index";
import "@material/button/dist/mdc.button.css";
import "@pwabuilder/pwaupdate";
import "./index.css";

import wildData from "./data.wild";
import poisonData from "./data.poison";
import civilizedData from "./data.civilized";

for (let el of document.getElementsByClassName("mdc-button")) {
    new MDCRipple(el);
}

let el = (sel: string) => document.querySelector(sel);
let clk = (sel: string, cb: EventListener) => el(sel).addEventListener("click", cb);

const wildTypes = {
    0: "лес",
    1: "луг",
    2: "болото",
    3: "водоёмы"
};
function genWild() {
    const inner = el(".wild-inner");
    inner.innerHTML = "";
    wildData
        .split("\n")
        .sort()
        .map(a => a.split(","))
        .forEach(el => {
            inner.innerHTML += el[0] + " — ";
            inner.innerHTML += wildTypes[parseInt(el[1])];
            if (el[2] != "0") {
                inner.innerHTML += ", " + el[2] + " ярус";
            }
            inner.innerHTML += "<br>";
        });
}

const poisonTypes = {
    0: "съедобное",
    1: "ядовитое"
};
function genPoison() {
    const inner = el(".poison-inner");
    inner.innerHTML = "";
    poisonData
        .split("\n")
        .sort()
        .map(a => a.split(","))
        .forEach(el => {
            inner.innerHTML += el[0] + " — ";
            inner.innerHTML += poisonTypes[parseInt(el[1])] + "<br>";
        });
}

const civilizedTypes = {
    0: "овощные",
    1: "плодово-ягодные",
    2: "зерновые",
    3: "масличные",
    4: "кормовые",
    5: "декоративные"
};
function genCivilized() {
    const inner = el(".civilized-inner");
    inner.innerHTML = "";
    civilizedData
        .split("\n")
        .sort()
        .map(a => a.split(","))
        .forEach(el => {
            inner.innerHTML += el[0] + " — ";
            inner.innerHTML += civilizedTypes[parseInt(el[1])] + "<br>";
        });
}

const bothData = wildData + "\n" + civilizedData;
function genBoth() {
    const inner = el(".both-inner");
    inner.innerHTML = "";
    bothData
        .split("\n")
        .sort()
        .map(a => a.split(","))
        .forEach(el => {
            inner.innerHTML += el[0] + " — ";
            if (el.length === 3) { // wild
                inner.innerHTML += " дикое: ";
                inner.innerHTML += wildTypes[parseInt(el[1])];
                if (el[2] != "0") {
                    inner.innerHTML += ", " + el[2] + " ярус";
                }
            } else if (el.length === 2) { // civilized
                inner.innerHTML += " культурное: ";
                inner.innerHTML += civilizedTypes[parseInt(el[1])];
            }
            inner.innerHTML += "<br>";
        });
}

genWild();
genPoison();
genCivilized();
genBoth();

import "@pwabuilder/pwaupdate";
const pwael = document.createElement("pwa-update");
document.body.appendChild(pwael);
