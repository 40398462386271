export default `
Ежевика,0
Малина,0
Брусника,0
Черника,0
Морошка,0
Костяника,0
Земляника,0
Лещина,0
Лебеда садовая,0
Одуванчик,0
Щавель,0
Крапива,0
Иван-чай,0
Рогоз,0
Клевер луговой,0
Лапчатка гусиная,0
Сныть,0
Подорожник большой,0
Пастушья сумка,0
Вех (цикута),1
Цикута (вех),1
Ландыш майский,1
Воронец колосистый,1
Волчеягодник обыкновенный,1
Красавка обыкновенная (белладонна),1
Белладонна (красавка обыкновенная),1
Бересклет бородавчатый,1
Вороний глаз,1
Чистотел большой,1
Белена чёрная,1
Лютик ядовитый,1
`.trim();