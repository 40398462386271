export default `
Дуб,0,1
Липа,0,1
Сосна,0,1
Береза,0,1
Яблоня дикая,0,2
Черемуха,0,2
Рябина,0,2
Лещина,0,3
Можжевельник,0,3
Калина,0,3
Бересклет,0,3
Папоротнтк,0,4
Крапива,0,4
Черника,0,4
Брусника,0,4
Ландыш,0,4
Кислица,0,5
Копытень,0,5
Костер безостый,1,1
Таволга вязолистная,1,1
Василистник жёлтый,1,1
Мятник луговой,1,2
Овсяница красная,1,2
Белоус,1,3
Мятник однолетный,1,3
Лядевнец рогатый,1,3
Клевер пашенный/ползучий,1,3
Гвоздика-травянка,1,3
Вероника дуброавная,1,3
Манжетка,1,3
Вербейник монетчатый,1,4
Очиток едкий,1,4
Тимьян ползучий,1,4
Вероника лекарственная,1,4
Сабельник болотный,2,0
Белокрыльник болотный,2,0
Вахта,2,0
Пушица,2,0
Морошка,2,0
Расянка круглолистная,2,0
Голубика,2,0
Клюква,2,0
Брусника,2,0
Багульник болотный,2,0
Тросник,3,0
Аир,3,0
Ежеголовник,3,0
Стрелолист,3,0
Рогоз,3,0
Камыш,3,0
Кувшинка,3,0
Кубышка,3,0
Рдест,3,0
Водокрас,3,0
Роголистник,3,0
Элодея канадская,3,0
Ряска,3,0
Сусак зонтичный,3,0
`.trim();
